import { useEffect, useState } from "react";
import { Rating } from "@smastrom/react-rating";
import { useSearchParams } from "react-router-dom";
import API from "../API";

import "@smastrom/react-rating/style.css";

function Survey() {
  const [questionData, setQuestionData] = useState(null);
  const [answer, setAnswer] = useState([]);
  const [rating, setRating] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const data = searchParams.get("data");

  const getQuestions = async () => {
    try {
      const res = await API.get(`/api/v1/questionaire/key/${data}`);
      if (res.data.error === 0) {
        setQuestionData(res.data.data);
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert(error);
    }
  };

  const handleChange = (value, question_id) => {
    const find = answer.find((item) => item.question_id === question_id);

    if (find) {
      const newValue = answer.map((item) => {
        const type = typeof value;
        if (item.question_id === question_id) {
          return {
            question_id,
            answer: type === "number" ? value : value.replace(/\r/g, ""),
          };
        } else {
          return item;
        }
      });
      setAnswer(newValue);
    } else {
      setAnswer((oldArr) => [
        ...oldArr,
        {
          question_id,
          answer: typeof value === "number" ? value : value.replace(/\r/g, ""),
        },
      ]);
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const payload = {
        token: questionData.token,
        answers: answer,
      };
      const res = await API.post(`/api/v1/questionaire/key/${data}`, payload);
      if (res.data.error === 0) {
        console.log("res", res);
        alert("Form submitted");
      } else {
        alert(res.data.message);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error);
    }
  };

  useEffect(() => {
    getQuestions();
  }, []);

  return (
    <div className="main-container">
      <div className="container pb-5">
        <div className="d-flex flex-column align-items-center justify-content-center pt-5 pb-3">
          <img
            src="https://main.mobile.doss.co.id/storage/uploads/2023/06/logo-doss.webp"
            alt="doss"
            className="main-logo"
          />
        </div>

        <div className="row">
          <div className="col-md-12 col-lg-6 mx-auto">
            <div className="main-card p-4">
              <form onSubmit={handleSubmit}>
                <h1 className="main-header text-center">DOSS Sales Review</h1>

                {questionData &&
                  questionData.questioner.questions.map((question) => {
                    if (question.description === "rating") {
                      return (
                        <div
                          className="d-flex flex-column mt-3"
                          key={question.id}
                        >
                          <label>{question.title}</label>
                          <Rating
                            style={{ maxWidth: 250 }}
                            value={rating}
                            onChange={(e) => {
                              setRating(e);
                              handleChange(e, question.id);
                            }}
                            isRequired={question.is_required}
                            isDisabled={loading}
                          />
                        </div>
                      );
                    }

                    if (question.description === "textarea") {
                      return (
                        <div className="d-flex flex-column mt-3">
                          <label>{question.title}</label>
                          <textarea
                            type="text"
                            className="form-control"
                            rows={4}
                            required={question.is_required}
                            value={
                              answer.find(
                                (item) => item.question_id === question.id
                              )?.answer || ""
                            }
                            onChange={(e) =>
                              handleChange(e.target.value, question.id)
                            }
                            disabled={loading}
                          />
                        </div>
                      );
                    }

                    if (question.description === "radio") {
                      return (
                        <div className="d-flex flex-column mt-3">
                          <label>{question.title}</label>
                          <div className="d-flex align-items-center gap-3">
                            {question.answer_list.map((item, index) => (
                              <div
                                className="d-flex align-items-center gap-1"
                                key={`${question.title}-rad-${index}`}
                              >
                                <input
                                  id={`radio-${question.id}-${item}`}
                                  type="radio"
                                  value={item}
                                  onChange={(e) =>
                                    handleChange(e.target.value, question.id)
                                  }
                                  checked={
                                    answer.find(
                                      (item) => item.question_id === question.id
                                    )?.answer === item.replace(/\r/g, "")
                                  }
                                  required={question.is_required}
                                  disabled={loading}
                                />
                                <label htmlFor={`radio-${question.id}-${item}`}>
                                  {item}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    }

                    if (question.description === "select") {
                      return (
                        <div className="d-flex flex-column mt-3">
                          <label>{question.title}</label>
                          <div className="d-flex align-items-center gap-3">
                            <select
                              className="form-select"
                              value={
                                answer.find(
                                  (item) => item.question_id === question.id
                                )?.answer || ""
                              }
                              onChange={(e) =>
                                handleChange(e.target.value, question.id)
                              }
                              required={question.is_required}
                              disabled={loading}
                            >
                              <option value="">Pilih salah satu</option>
                              {question.answer_list.map((item, index) => (
                                <option
                                  key={`${question.id}-select-${index}`}
                                  value={item.replace(/\r/g, "")}
                                >
                                  {item}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      );
                    }
                  })}

                <button
                  type="submit"
                  className="btn btn-primary w-100 mt-4"
                  disabled={loading}
                >
                  {loading ? (
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only"></span>
                    </div>
                  ) : (
                    "Kirim"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Survey;
